import { Button, Empty, notification, Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { adminDashboardApi } from "../../../../apiservice/admin-General-ApiService";
import useFormatApiRequest from "../../../../hooks/formatApiRequest";
import useAuth from "../../../../hooks/useAuth";
import { useAppDispatch } from "../../../../Redux/reduxCustomHook";
import { ILoadState } from "../../../../utils/loading.utils.";
import AdminChartCashFlow from "../../adminCharts/adminChart-CashFlow";
import AdminChartCashProjection from "../../adminCharts/adminChart-CashProjection";
import AdminChartOccupancy from "../../adminCharts/adminChart-OccupancyRate";
import AdminChartRevenue from "../../adminCharts/adminChart-Revenue";
import AdminTableRevenueTab from "../../adminCharts/adminTable-Revenue-Tab";
import AdminTableUpcomingRevenue from "../../adminCharts/adminTable-Upcoming-Revenue";
import "./admin-dashboard.css";
type NotificationType = "success" | "info" | "warning" | "error";

type IAdminDashboard = {
  externalFilter?: any;
  initialDefaultFilter?: any;
};

export const AdminDashboard: React.FC<IAdminDashboard> = ({
  externalFilter,
  initialDefaultFilter,
}) => {
  const [adminDashboardLoadState, setAdminDashboardLoadState] =
    useState<ILoadState>("loading");
  const [loadAdminDashboardData, setLoadAdminDashboardData] = useState(true);
  const [adminDashboardDefaultFilter, setAdminDashboardDefaultFilter] =
    useState(initialDefaultFilter || {});
  const [tableData, setTableData] = useState<any[]>([]);
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useAppDispatch();


  const [loadAuth, setLoadAuth] = useState(false);

  // Use to refresh the Auth State
  const authState = useAuth(loadAuth, () => {
    setLoadAuth(false);
  });


  // Use Effect to reload API when External Filter has changed
  useEffect(() => {
    if (externalFilter) {
      setAdminDashboardDefaultFilter({
        ...adminDashboardDefaultFilter,
        ...externalFilter,
      });
      setLoadAdminDashboardData(true);
      setAdminDashboardLoadState("loading");
       setLoadAuth(true);
    }
  }, [externalFilter]);

  // A custom hook to Load All AdminDashboard Details
  const adminDashboardDataResult = useFormatApiRequest(
    () => adminDashboardApi(adminDashboardDefaultFilter),
    loadAdminDashboardData,
    () => {
      setLoadAdminDashboardData(false);
    },
    () => {
      processAdminDashboardResult();
    }
  );

  // Process The Current AdminDashboard Data Result
  const processAdminDashboardResult = async () => {
    if (adminDashboardDataResult.httpState === "SUCCESS") {
      // setTableData(adminDashboardDataResult.data?.data || [{}]);
      dispatch({
        type: "ADMIN_ADD_DASHBOARD_DATA",
        payload: adminDashboardDataResult.data?.data,
      });
      setAdminDashboardLoadState("completed");
    } else if (adminDashboardDataResult.httpState === "ERROR") {
      setAdminDashboardLoadState("error");
    } else if (adminDashboardDataResult.httpState === "LOADING") {
      setAdminDashboardLoadState("loading");
    }
  };

  // Show Notification
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string,
    background?: string
  ) => {
    api[type]({
      message,
      description,
      placement: "bottomRight",
      style: { background },
    });
  };

  return (
    <>
      {/* " The context is use to hold the notification from ant design" */}
      {contextHolder}
      <div>
        {/* " Show Loading Indicator" */}
        {adminDashboardLoadState === "loading" && (
          <div
            className="w3-col w3-center w3-padding-bottom"
            style={{ paddingTop: "100px" }}
          >
            <Spin size="large" />
          </div>
        )}

        {/* " Show Loading Error" */}
        {adminDashboardLoadState === "error" && (
          <div className="w3-col w3-padding-bottom">
            <Result
              status="500"
              title={<span className="w3-text-white">Error</span>}
              subTitle={
                <span className="w3-text-white">
                  Sorry, something went wrong, it could be a network Related
                  error
                </span>
              }
              extra={
                <Button
                  onClick={() => setLoadAdminDashboardData(true)}
                  type="primary"
                >
                  Reload
                </Button>
              }
            />
          </div>
        )}

        {/* " Show No data" */}
        {adminDashboardLoadState === "noData" && (
          <div className="w3-margin-top">
            <Empty></Empty>
          </div>
        )}

        {/* " Show No data" */}
        {adminDashboardLoadState === "completed" && (
          <>
            <div className="w3-col w3-margin-top">
              <AdminChartRevenue></AdminChartRevenue>
              <AdminTableRevenueTab></AdminTableRevenueTab>
              <AdminChartCashFlow></AdminChartCashFlow>
              <AdminChartCashProjection></AdminChartCashProjection>
              <AdminTableUpcomingRevenue></AdminTableUpcomingRevenue>
              <AdminChartOccupancy></AdminChartOccupancy>
            </div>
          </>
        )}
      </div>
    </>
  );
};
